import type { AxiosResponse } from "axios";
import axios from "axios";
import { BOOKING_ADJUSTED_MESSAGE } from "helpers/messages";
import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { UserBooking } from "types/model/cart";

interface GetCartResponse {
  cart: UserBooking;
  isAdjusted: boolean;
}

const getCart = async (): Promise<UserBooking> => {
  const response: AxiosResponse<GetCartResponse> = await axios.get("/api/cart");

  if (response.data.isAdjusted) {
    window.alert(BOOKING_ADJUSTED_MESSAGE);
    window.location.reload();
  }

  return response.data.cart;
};

export default function useCart(): QueryResult<UserBooking> {
  return useQuery(["cart"], getCart);
}
