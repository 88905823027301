"use client";

import useCart from "hooks/useCart";
import Link from "next/link";

export const CartButton = ({ isMobile }: { isMobile?: boolean }) => {
  const { data: cartData } = useCart();

  return (
    <Link
      href="/cart"
      data-cy={`link-cart${isMobile ? "-mobile" : ""}`}
      passHref
      className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
    >
      <svg
        className="h-6 w-6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
      </svg>
      {(cartData?.lineItemsCount ?? 0) > 0 && (
        <span
          style={{ minWidth: "1rem" }}
          className="absolute right-0 top-0 block h-4 rounded-full bg-blue-100 text-center text-xs font-medium leading-4 text-blue-800"
          data-cy={`cart-count${isMobile ? "-mobile" : ""}`}
        >
          {cartData?.lineItemsCount}
        </span>
      )}
    </Link>
  );
};
