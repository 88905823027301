"use client";

import axios from "axios";
import { Button } from "components/ui/button";
import { isProtectedRoute } from "helpers/auth";
import { usePathname, useRouter } from "next/navigation";
import { memo } from "react";
import { queryCache } from "react-query";
import { getErrorMessage } from "utils/getErrorMessage";

export const LogoutButton = memo(function LogoutButton({
  isMobile,
  close
}: {
  isMobile?: boolean;
  close?: () => void;
}) {
  const router = useRouter();
  const pathname = usePathname();

  async function logout() {
    try {
      await axios.delete("/api/auth");

      queryCache.invalidateQueries("currentUser");
      queryCache.invalidateQueries("cart");

      if (isProtectedRoute(pathname || "")) {
        router.push("/");
      }

      if (window["Beacon"] && window["Beacon"]("info")) {
        window["Beacon"]("destroy");
      }

      router.refresh();
    } catch (error: unknown) {
      throw new Error(
        getErrorMessage({
          error,
          errorMessage: error => `Failed to log out: ${error.message}`,
          otherErrorMessage: "Failed to log out."
        })
      );
    }
  }

  if (isMobile) {
    return (
      <Button
        variant="unstyled"
        onClick={() => {
          logout();
          close?.();
        }}
        className="block w-full cursor-pointer bg-gray-50 px-5 py-3 text-center text-base font-medium text-indigo-600 hover:bg-gray-100"
      >
        Log out
      </Button>
    );
  }

  return (
    <Button
      variant="unstyled"
      onClick={() => logout()}
      className="whitespace-no-wrap cursor-pointer p-0 text-sm font-medium leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none sm:text-base"
      data-cy="link-logout"
    >
      Log out
    </Button>
  );
});
